<template>
    <div>
        <b-button
            :to="{ name:'create-actor'}"
            class="mb-1 mr-1"
            variant="relief-primary"
        >
            {{ $t('new') }}
        </b-button>
        <b-button
            class="mb-1"
            variant="info"
            @click="visible = !visible"
        >
            {{ $t('search') }}

        </b-button>
        <custom-filter
            :filters="filters"
            :visible="visible"
            @onSubmit="filters => filter(filters)"
        >
            <template #form="{filters}">
                <b-row>
                    <b-col md="6">
                        <custom-input
                            v-model="filters.name"
                            :name="$t('name')"
                            vid="name"
                        />
                    </b-col>
                </b-row>
            </template>
        </custom-filter>
        <b-card no-body>
            <b-table
                :busy="status === 'LOADING'"
                :fields="fields"
                :items="Object.values(actors)"
                hover
                responsive
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle" />
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-dropdown
                        no-caret
                        variant="link"
                    >
                        <template #button-content>
                            <feather-icon icon="MoreHorizontalIcon" />
                        </template>
                        <b-dropdown-item :to="{name: 'edit-actor', params:{id: data.item.id}}">
                            <feather-icon icon="Edit2Icon" />
                            {{ $t('edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="XIcon" />
                            {{ $t('delete') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-pagination
                v-if="pagination.last_page > 1"
                v-model="pagination.current_page"
                :per-page="pagination.per_page"
                :total-rows="pagination.total"
                align="center"
                class="mt-2"
                @change="paginationCategory"
            />
        </b-card>
    </div>

</template>

<script>
import fields from "./partials/fields";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Actors",

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchActors()
        })
    },

    data() {
        return {
            fields,
            visible: false,
            filters: {
                title: '',
            }
        }
    },

    computed: {
        ...mapGetters('actor', [
            'actors', 'status', 'pagination'
        ]),
        ...mapGetters('filter', [
            'filterFields'
        ])
    },

    methods: {
        ...mapActions({
            fetchActors: 'actor/fetch',
            deleteActor: 'actor/delete'
        }),
        filter(filters) {
            this.fetchActors({
                ...filters,
                page: 1
            })
        },
        paginationCategory(page) {
            this.fetchActors({
                page,
                ...this.filterFields
            })
        },
        confirmDelete(id) {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You won't be able to revert this!"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes, delete it!'),
                cancelButtonText: this.$t('No'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.deleteActor(id).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.$t(`Deleted!`),
                            text: this.$t('Your file has been deleted.'),
                            confirmButtonText: this.$t('Ok!'),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    })
                }
            })
        },
    }
}
</script>
