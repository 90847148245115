import i18n from "@/libs/i18n";

const fields = [
    {
        key: 'name',
        label: i18n.t('name'),
    },
    {
        key: 'posts_count',
        label: i18n.t('posts count'),
    },
    {
        key: 'action',
        label: i18n.t('action'),
    },
]

export default fields